<template>
  <div class="image-cropper-content">
    <input id="cropper-file-input" ref="input" class="hidden-input" type="file" accept="image/*" @input="setImage($event)" />
    <v-container class="pa-0">
      <v-row>
        <v-col cols="8">
          <v-row>
            <mf-icon @click="showFileChooser">folder</mf-icon>
            <mf-icon @click="reset">settings_backup_restore</mf-icon>
            <v-spacer />
            <mf-icon @click="move(-10, 0)">keyboard_arrow_left</mf-icon>
            <mf-icon @click="move(10, 0)">keyboard_arrow_right</mf-icon>
            <mf-icon @click="move(0, -10)">keyboard_arrow_up</mf-icon>
            <mf-icon @click="move(0, 10)">keyboard_arrow_down</mf-icon>
            <mf-icon @click="zoom(-0.05)">zoom_out</mf-icon>
            <mf-icon @click="zoom(0.05)">zoom_in</mf-icon>
          </v-row>
          <v-row>
            <div class="image-cropper">
              <vue-cropper v-if="imgSrc" ref="cropper" :src="imgSrc" preview=".preview" />
              <v-btn v-else color="primary" @click="showFileChooser">Selecione uma imagem</v-btn>
            </div>
          </v-row>
        </v-col>
        <v-col cols="4">
          <p class="preview-title">Preview</p>
          <span>Tamanho ideal 1400px por 350px</span>
          <div class="preview" :style="{ height: `${372 / aspectRatio}px` }" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper
  },
  props: {
    value: Boolean,
    aspectRatio: {
      type: Number,
      required: true
    },
    mbSizeLimit: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      imgSrc: '',
      data: null,
      fileName: ''
    }
  },
  methods: {
    convertByteToMegabyte(bytes) {
      const megabyte = bytes / 1000000
      return megabyte
    },
    cropImage() {
      return this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    getFileName() {
      return this.fileName
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY)
    },
    reset() {
      this.$refs.cropper.reset()
    },
    setImage(e) {
      const file = e.target.files[0]
      if (this.mbSizeLimit && this.mbSizeLimit < this.convertByteToMegabyte(file.size)) {
        document.querySelector('#cropper-file-input').value = '' // This enables secting the same file again
        this.$snackbar({ message: `Não é possível adicionar imagens com mais de ${this.mbSizeLimit} MB.`, snackbarColor: 'error' })
        return
      }

      if (!file.type.includes('image/')) {
        document.querySelector('#cropper-file-input').value = '' // This enables secting the same file again
        this.$snackbar({ message: 'Por favor selecione uma imagem', snackbarColor: 'error' })
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.imgSrc = event.target.result
          this.$emit('input', true) // Signal that you are croppable
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result)
          }
        }
        this.fileName = file.name
        reader.readAsDataURL(file)
      } else {
        document.querySelector('#cropper-file-input').value = '' // This enables secting the same file again
        this.$snackbar({ message: 'Não é possível selecionar arquivos', snackbarColor: 'error' })
      }
    },
    showFileChooser() {
      this.$refs.input.click()
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent)
    }
  }
}
</script>

<style lang="scss">
.image-cropper-content {
  .hidden-input {
    display: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 5px 0;
  }

  .header h2 {
    margin: 0;
  }

  .header a {
    text-decoration: none;
    color: black;
  }

  .actions {
    margin-top: 1rem;
  }

  .actions a {
    display: inline-block;
    padding: 5px 15px;
    background: #0079f2;
    color: #ffffff;
    text-decoration: none;
    border-radius: 3px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .preview-area {
    width: 307px;
  }

  .preview-title {
    font-size: 1.25rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  .preview-area p:last-of-type {
    margin-top: 1rem;
  }

  .preview {
    width: 100%;
    overflow: hidden;
  }

  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #d2d6d9;
  }

  .image-cropper {
    width: 800px;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f1f2;
    img {
      object-fit: contain;
    }
  }
}
</style>
